import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
  <>
 <footer aria-label="Site Footer" className="bg-gray-900">
  <div className="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
    <div className="flex justify-center text-indigo-500">
      <h1 className='whitespace-nowrap text-3xl font-bold cursor-pointer hover:text-white'>FOXY</h1>
    </div>

    <p
      className="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500"
    >
      A advance security discord bot over <strong>35+ security modules</strong> which always protect you 24/7 without any charge.
    </p>

    <nav aria-label="Footer Nav" className="mt-12">
      <ul className="flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
        <li>
          <Link
            className="text-indigo-500 transition "
            to="/support"
          >
            Support
          </Link>
        </li>

        <li>
          <a
            className="text-indigo-500 transition "
            href="/login"
          >
            Login
          </a>
        </li>

        <li>
          <Link
            className="text-indigo-500 transition "
            to="/invite"
          >
            Invite
          </Link>
        </li>

        <li>
          <a
            className="text-indigo-500 transition "
            href="https://totalfun.in"
          >
            Total Fun
          </a>
        </li>

        <li>
          <a
            className="text-indigo-500 transition "
            href="/"
          >
            Status
          </a>
        </li>

        <li>
          <Link
            className="text-indigo-500 transition "
            to="/soon"
          >
            Mobile
          </Link>
        </li>
      </ul>
    </nav>

   </div>
</footer>

  </>
  )
}
