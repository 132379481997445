import React from 'react'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import { Link } from 'react-router-dom';
export default function Intro() {
 
  return (
    <>
      <Navbar />
      <div
        className="container max-w-lg px-4 py-32 mx-auto text-left md:max-w-none md:text-center "
      >
        <h1
          className="text-5xl font-extrabold leading-10 tracking-tight text-left text-white md:text-center sm:leading-none md:text-6xl lg:text-7xl"
        >
          <span className="inline md:block text-uppercase">Secure Your Discord Server</span>
         
          <span
            className="relative mt-2 text-transparent bg-clip-text bg-gradient-to-br from-indigo-600 to-indigo-500 md:inline-block"
          > With <span className='text-white'>FOXY</span></span>
        </h1>
        <div
          className="mx-auto mt-5 text-white md:mt-12 md:max-w-lg md:text-center lg:text-lg"
        >
          Protect your favourite discord server with 35+ protection modules and the power of artificial intelligence  .
        </div>
        <div className="flex flex-col items-center mt-12 text-center">

        <Link to="/invite" className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-700 transition duration-300 ease-out border-2 bg-white rounded-full shadow-md group">
<span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-700 group-hover:translate-x-0 ease">
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
</span>
<span className="absolute flex items-center justify-center w-full h-full text-black transition-all duration-300 transform group-hover:translate-x-full ease font-bold">Secure Now</span>
<span className="relative invisible">Secure Now</span>
</Link>
          <i className='fas fa-fingerprint' />

          <Link to="/support" className="mt-3 text-sm text-indigo-500 hover:text-white">Learn More</Link>
        </div>
      </div>

      {/* WHY FOXY */}
     
        <h3 className="text-2xl tracking-widest text-indigo-500 text-center reveal fade-bottom">SECURITY</h3>
        <h1 className="mt-8 text-center text-5xl text-white font-bold  ">Protect Your Server</h1>


        <div className="md:flex md:justify-center md:space-x-8 md:px-14 py-10 cursor-pointer reveal fade-bottom">

          <div className="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
            <div className="w-sm">
              <img className="w-64" src="/png1.png" alt="security" />
              <div className="mt-4 text-indigo-500 text-center">
                <h1 className="text-xl font-bold">35+ Powerful Modules</h1>
                <p className="mt-4 text-gray-600">Experience fully secured server with Foxy's 35+ advance security modules which comes with recovery system and it covers A to Z security of your <span className='text-indigo-500'>Discord Server.</span></p>
                
                  <Link to='/support'><button className="mt-8 mb-4 py-2 px-14 rounded-full bg-indigo-500 text-white tracking-widest hover:bg-indigo-700 transition duration-200">Learn More</button>   </Link>
            
              </div>
            </div>
          </div>


          <div className="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0 ">
            <div className="w-sm">
              <img className="w-64" src="png2.png" alt="png2" />
              <div className="mt-4 text-indigo-500 text-center">
                <h1 className="text-xl font-bold">5+ Whitelist Modes</h1>
                <p className="mt-4 text-gray-600">Experience secure 5+ whitelist options that allow you to grant proper permission to your server managers and your truted one .</p>
                  <Link to='/support'><button className="mt-8 mb-4 py-2 px-14 rounded-full bg-indigo-500 text-white tracking-widest hover:bg-indigo-700 transition duration-200">Learn More</button>   </Link>
              </div>
            </div>
          </div>


          <div className="mt-16 py-4 px-4 bg-whit w-72 bg-white rounded-xl shadow-lg hover:shadow-xl transform hover:scale-110 transition duration-500 mx-auto md:mx-0">
            <div className="w-sm">
              <img className="w-64" src="/pn3.png" alt="png3" />
              <div className="mt-4 text-indigo-500 text-center">
                <h1 className="text-xl font-bold">Secure Mode</h1>
                <p className="mt-4 text-gray-600">An advance ai based security mode that can be used at any emergency situation or when you lost trust on your management.</p>
                  <Link to='/support'><button className="mt-8 mb-4 py-2 px-14 rounded-full bg-indigo-500 text-white tracking-widest hover:bg-indigo-700 transition duration-200">Learn More</button>   </Link>
              </div>
            </div>
          </div>
        </div>

 


      <div className="mx-auto max-w-screen-lg bg-[#3b3aac] p-8 text-white md:flex md:items-center md:justify-around md:p-16 lg:rounded-xl reveal fade-right">
  <div className="mr-10 mb-10 md:mb-0">
    <h2 className="mb-8 max-w-lg text-3xl font-bold sm:text-4xl">Don't have to worry again for server security</h2>
    <ul className="flex max-w-xl flex-wrap gap-4">
      <li className="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-green-300">
          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
        </svg>
        <p className="text-gray-100 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">35+ Active Security Modules</p>
      </li>
      <li className="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-green-300">
          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
        </svg>
        <p className="text-gray-100 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">5+ Whitelist Modes</p>
      </li>
      <li className="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-green-300">
          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
        </svg>
        <p className="text-gray-100 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">Recovery System</p>
      </li>
      <li className="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-green-300">
          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
        </svg>
        <p className="text-gray-100 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">Artificial Intelligence Protection</p>
      </li>
      <li className="flex space-x-2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6 text-green-300">
          <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
        </svg>
        <p className="text-gray-100 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300">Quick Action</p>
      </li>
    </ul>
  </div>

  <div className="whitespace-nowrap">
  <Link to="/invite" className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 bg-white rounded-full shadow-md group">
<span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
</span>
<span className="absolute flex items-center justify-center w-full h-full text-black transition-all duration-300 transform group-hover:translate-x-full ease">Secure Now</span>
<span className="relative invisible">Secure Now</span>
</Link>
  </div>
</div>

<div className=" py-6 sm:py-8 lg:py-12 reveal fade-bottom">
  <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
    <h2 className="text-white text-2xl lg:text-3xl font-bold text-center mb-8 md:mb-12">What others say about us</h2>

    <div className="grid md:grid-cols-2 gap-4 md:gap-8">
     
      <div className="flex flex-col items-center bg-[#3b3aac] rounded-lg gap-4 md:gap-6 px-8 py-6">
        <div className="max-w-md text-white lg:text-lg text-center">“It is a great security service i have ever found , It has best security modules and recovery system”</div>

        <div className="flex flex-col sm:flex-row items-center gap-2 md:gap-3">
          <div className="w-12 md:w-14 h-12 md:h-14 bg-gray-100 border-2 border-indigo-100 rounded-full overflow-hidden">
            <img src="https://images-ext-1.discordapp.net/external/fzs7Na_nqabDWuACxyBtlAZCjVtgtQl2w0wOEE3dkQw/%3Fsize%3D4096/https/cdn.discordapp.com/avatars/427528570462535680/2698bdf9d397aac84aaa6915f7a36f4b.webp" loading="lazy" alt="COOL_xD" className="w-full h-full object-cover object-center" />
          </div>

          <div>
            <div className="text-indigo-50 text-sm md:text-base font-bold text-center sm:text-left">COOL_xD69</div>
            <p className="text-indigo-200 text-sm md:text-sm text-center sm:text-left">Server Admin</p>
          </div>
        </div>
      </div>
    
      <div className="flex flex-col items-center bg-[#3b3aac] rounded-lg gap-4 md:gap-6 px-8 py-6">
        <div className="max-w-md text-white lg:text-lg text-center">“It is a unique security bot i have ever saw , It has best security modules and unique features"</div>

        <div className="flex flex-col sm:flex-row items-center gap-2 md:gap-3">
          <div className="w-12 md:w-14 h-12 md:h-14 bg-gray-100 border-2 border-indigo-100 rounded-full overflow-hidden">
            <img src="https://media.discordapp.net/attachments/1021096101144707112/1049005655446519858/mrboy_gfx_logo.png?width=427&height=427" loading="lazy" alt="Mr_Boy" className="w-full h-full object-cover object-center" />
          </div>

          <div>
            <div className="text-indigo-50 text-sm md:text-base font-bold text-center sm:text-left">MrBoyᴳᶠˣ</div>
            <p className="text-indigo-200 text-sm md:text-sm text-center sm:text-left">Gfx Artist</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<br/>
<h3 className="text-2xl tracking-widest text-indigo-500 text-center reveal fade-bottom">MODES</h3>
<div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12 cursor-pointer">
        <div className="relative">
            <h2 className="w-full text-2xl font-bold text-center sm:text-4xl md:text-5xl text-white"> Special Modes</h2>
            <p className="w-full py-8 mx-auto -mt-2 text-lg text-center text-white intro sm:max-w-3xl">Foxy comes with pre-installed special security modes for your server protection.</p>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row reveal fade-bottom reveal fade-right">
            <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12 sm:order-last">
                <img className="rounded-lg shadow-xl hover:animate-pulse" src="/secure.PNG" alt="Secure"/>
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pr-16">
                <p className="mb-2 text-sm font-bold leading-none text-left text-indigo-500 uppercase ">SECURE MODE</p>
                <h3 className="mt-2 text-2xl font-semibold sm:text-left md:text-3xl text-white">Security in next level</h3>
                <p className="mt-5 text-lg text-white text md:text-left">An advance security modules which can be enabled at the time when the owner think the server is in danger or he lost the trust on his management , It comes with a special whitelist mode named Secure Pass.</p>
            </div>
        </div>
        <div className="flex flex-col mb-8 animated fadeIn sm:flex-row reveal fade-left ">
            <div className="flex items-center mb-8 sm:w-1/2 md:w-5/12">
                <img className="rounded-lg shadow-xl hover:animate-pulse" src="/webhook.PNG" alt="secure_mode"/>
            </div>
            <div className="flex flex-col justify-center mt-5 mb-8 md:mt-0 sm:w-1/2 md:w-7/12 sm:pl-16 reveal fade-bottom">
                <p className="mb-2 text-sm font-bold leading-none text-left text-indigo-500 uppercase">WEBHOOK PROTECTION</p>
                <h3 className="mt-2 text-2xl sm:text-left md:text-3xl text-white font-semibold">Avoid webhook spam</h3>
                <p className="mt-5 text-lg text-white text md:text-left">Foxy comes with pre-installed security module which protect your server from unknown webhook spam , Don't have to worry again for webhook spams and webhook unknown messages.</p>
            </div>
        </div>
    </div>

    <div className="mx-auto container py-12 px-4 reveal fade-bottom">
            <div className="w-full flex justify-center">
                <div className="w-full md:w-11/12 xl:w-10/12 bg-gradient-to-r from-indigo-500 to-indigo-700 md:py-8 md:px-8 px-5 py-4 xl:px-12 xl:py-16">
                    <div>
                        <div className="flex flex-wrap items-center md:flex-row flex-col-reverse">
                            <div className="md:w-2/3 w-full pb-6 md:pb-0 md:pr-6 flex-col md:block flex items-center justify-center md:pt-0 pt-4">
                                <div >
                                    <h1 className="text-xl md:text-2xl lg:text-4xl xl:text-4xl lg:w-10/12 text-white font-black leading-6 lg:leading-10 md:text-left text-center">Protect Your Server From Nukers & Raiders Now </h1>
                                </div>
                              <br/>
                              <Link to="/invite" className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 bg-white rounded-full shadow-md group">
<span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-indigo-500 group-hover:translate-x-0 ease">
<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
</span>
<span className="absolute flex items-center justify-center w-full h-full text-black transition-all duration-300 transform group-hover:translate-x-full ease">Secure Now</span>
<span className="relative invisible">Secure Now</span>
</Link>
                            </div>
                            <div className="md:w-1/3 w-2/3">
                               <img src="/done.svg" alt="intro_svg_he"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

<br/>


<Footer/>
        
    </>
  )
}
