import React from 'react'
import { Link } from 'react-router-dom';
export default function Navbar() {
  return (
    
  <header className="flex-col  px-4  text-white md:mx-auto md:flex-row  overflow-hidden bg-[url()] bg-cover  bg-no-repeat">
     
  
      <div className="px-5 xl:px-12 py-6 flex w-full items-center">
      <Link to="/" className="flex items-center whitespace-nowrap text-2xl font-bold">
FOXY
</Link>
<nav className="flex justify-between items-center text-white w-screen">
        <ul className="hidden md:flex px-4 mx-auto font-semibold font-heading space-x-12">
          <li><Link className="hover:text-gray-300 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300" to="/">Guide</Link></li>
          <li><Link className="hover:text-gray-200 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300" to="/support">Support</Link></li>
          <li><Link className="hover:text-gray-200 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300" to="/invite">Invite</Link></li>
          <li><Link className="hover:text-gray-200 relative after:absolute after:bg-gray-200 after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-right after:scale-x-0 hover:after:origin-bottom-left hover:after:scale-x-100 after:transition-transform after:ease-in-out after:duration-300" to="/">Status</Link></li>
        </ul>
       
</nav>
        <Link
  className="md:mr-9  rounded bg-indigo-600 px-8 py-3 text-sm font-medium text-white transition hover:-rotate-2 hover:scale-110 focus:outline-none focus:ring active:bg-indigo-500"
  to="/login"
>

  Login
</Link>



       </div>
  
    
  </header>


  )
}


