import React from 'react'
import Navbar from './Navbar'

export default function ComingSoon() {
  return (
   <>
   <Navbar/>
   <div className="w-full">
    <div className="flex h-screen">
        <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
            <div>
                 <span className="text-2xl font-semibold text-white md:text-4xl">⏰ Coming<span className="ml-2 text-blue-600">Soon</span> ⏰</span>
                
              <p className="mt-2 text-sm text-white md:text-lg"><a href="https://discord.gg/snaps">Foxy</a> is the best advance discord security bot which protect your server with <strong>35+ modules</strong> without any cost . Coming Soon... </p>
                
                </div>
        </div>
        <div className="hidden lg:block lg:w-1/2" >
            <div className="h-full   bg-[url(/police.png)] bg-cover  bg-no-repeat" >
                <div className="h-full "></div>
            </div>
        </div>
    </div>
</div>
   </>

  )
}
