
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Redirect from './components/Redirect';
import Intro from './pages/Intro';
import Foxystate from './utils/Foxystate'
import Settings from './pages/Settings';
import Server from './pages/Server';
import Manage from './pages/Manage';
import Premium from './pages/Premium';
import Guild from './pages/Guild';
import Login from './components/Login';
import Term from './components/Term';
import ComingSoon from './components/ComingSoon';
import Support from './components/Support';

function App() {
  return (
   <>
  <Router>
  <Foxystate>
    <Routes>
    
    
      <Route exact path="/" element={<Intro/>}/>
      <Route exact path="/*" element={<ComingSoon/>}/>
      <Route exact path="/support" element={<Support/>}/>
 <Route exact path="/invite" element={<Redirect/>}/>
  
<Route exact path="/soon" element={<ComingSoon/>}/>
     {/* <Route exact path="/invite" element={<Redirect/>}/>
      <Route exact path="/user" element={<Server/>}/>
      <Route exact path="/profile" element={<Settings/>}/>
       <Route exact path="/server" element={<Manage/>}/>
       <Route exact path="/premium" element={<Premium/>}/>
       <Route exact path="/manage/:id" element={<Guild/>}/>
       <Route exact path="/login" element={<Login/>}/>
       <Route exact path="/term" element={<Term/>}/>
       <Route exact path="/soon" element={<ComingSoon/>}/>
       */
  }
    </Routes>
    </Foxystate>
</Router>

   </>
  );
}

export default App;
