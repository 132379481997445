import FoxyContext from "./foxyContext";
import { useState } from "react";

const Foxystate = (props) => {

  const foxyInitial = []
  const [data, setData] = useState(foxyInitial)

  // Get Auth Data
  const getAuth = async () => {
    // API Call 
    const response = await fetch("/api/auth/data", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',

        },
        credentials: 'include'

    });

    const json = await response.json() 
    console.log(json)
    setData(json)
  }

  
  return (
    <FoxyContext.Provider value={{ data, getAuth }}>
      {props.children}
    </FoxyContext.Provider>
  )

}
export default Foxystate;