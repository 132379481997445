import React from 'react'
import { Link } from 'react-router-dom';

export default function Redirect() {
    const onLoad = () => {
        window.setTimeout(function(){
            window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1201416495343869952&permissions=8&scope=bot%20applications.commands";
    
        }, 5000);
    }
  return (
   <>
  
 <section className="flex items-center h-full sm:p-16 text-gray-100" onLoad={onLoad()}>
	<div className="container flex flex-col items-center justify-center px-5 mx-auto my-8 space-y-8 text-center sm:max-w-md">
    <svg className="animate-spin  w-40 h-40  text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
		<p className="text-3xl">Redirecting You In Few Seconds !</p>
		<Link rel="noopener noreferrer" to="/" className="px-8 py-3 font-semibold rounded bg-violet-400 text-gray-900">Back to home</Link>
	</div>
</section>
   </>
  )
}
